.signatureForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.sigCanvas {
    width: 400px;
    height: 200px;
    border: 2px solid #000;
    background-color: #fff;
}
@media screen and (max-width: 575px) {
    .signatureForm {
        margin-top: 10px;
    }
    .sigCanvas {
        width: 100%;
    } 
}