.bannerBox {
    background-image: url(../../../assets/images/BannerBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: background-color .3s;
    }

    // &:hover {
    //     &::before {
    //         background-color: rgba(240, 185, 19, .85);
    //     }
    //     h4{
    //         color: var(--secondaryClr);
    //     }
    //     h5{
    //         color: var(--whiteClr);
    //     }
    // }
}