.setWidth{
    width: calc(33.33% - 20px);
    @media screen and (max-width: 991px) {
        width: calc(50% - 8px);
    }
    @media screen and (max-width: 575px) {
        width: 100%;
    }
    &.setWidth50{
        width: calc(50% - 20px);
        @media screen and (max-width: 575px) {
            width: 100%;
        }
    }
}
.inputWidth{
    width: calc(50% - 6px);
    @media screen and (max-width: 575px) {
        width: 100%;
    }
}