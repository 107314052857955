.steps-status-section.form-steps{
    padding: 0 0 20px;

    .order-details{
        .text-primary{
            margin-top: 35px;
        }

        .form-steps-status{
            width: 100%;
            .status-tree{ 
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                margin-top: 43px;
                .status-tree-item{
                    width: 15vw;
                    position: relative;
                    &::before{
                        content: '';
                        width: 15vw;
                        height: 5px;
                        background: rgba(255, 255, 255, 0.3);
                        position: absolute;
                        right: 100%;
                        top: 17px;
                        transform: translateY(-50%);
                        display: block;
                        z-index: 0;
                    }
                    &:first-child{
                        &::before{
                            display: none;
                        }
                    }
                    &:last-child{
                        width: max-content;
                    }
                    
                    .item-icon{
                        width: 36px;
                        height: 36px;
                        min-width: 36px;
                        min-height: 36px;
                        border-radius: 50%;
                        color: #ffffff;
                        background: #3a3a3a; 
                        padding: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 2;
                        svg{
                            width: 80%;
                            height: 80%;
                            object-fit: contain;
                        }
                    }
                    &.active{
                        &::before{
                            background: #fff;
                        }
                        .item-icon{
                            background: #262829;
                            color: #ffffff;
                            position: relative;
                            &::before, &::after{
                                content: '';
                                width: 120%;
                                height: 120%;
                                border-radius: 50%;
                                background: transparent;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            &::before{
                                border: 2px solid #262829;
                                opacity: 0.6;
                            }
                            &::after{
                                border: 1px solid #ffffff;
                                width: 160%;
                                height: 160%;
                                opacity: 0.4;
                            }
                        }
                        .item-status{
                            opacity: 1;
                        }
                    }
                    &.completed{
                        .item-icon{
                            background: #fff;
                            color: #000;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        
                        &::before{
                            background: #fff;
                        }
                    }
                    .item-title{
                        margin: 11px 0 1px;
                        color: #333537;
                    }
                }
            }
        }
    }
}

.order-tracking-section{
    padding: 20px 0 50px;
    .order-details{
        .text-primary{
            margin-top: 35px;
        }
        .order-status-section{
            width: 100%;
        }

        .order-track-status{
            width: 100%;
            .status-tree{ 
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                margin-top: 43px;
                .status-tree-item{
                    width: 10.5vw;
                    position: relative;
                    padding-right: 8px;
                    &::before{
                        content: '';
                        width: 10.5vw;
                        height: 5px;
                        background: rgba(240, 185, 19, 0.4);
                        position: absolute;
                        right: 100%;
                        top: 15px;
                        transform: translateY(-50%);
                        display: block;
                        z-index: 0;
                    }
                    &:first-child{
                        &::before{
                            display: none;
                        }
                    }
                    &:last-child{
                        width: max-content;
                    }
                    
                    .item-icon{
                        width: 30px;
                        height: 30px;
                        min-width: 30px;
                        min-height: 30px; 
                        border-radius: 50%;
                        color: #ffffff;
                        background: #3a3a3a; 
                        padding: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 2;
                        i{
                            font-size: 14px;
                        }
                    }
                    &.active{
                        &::before{
                            background: #f0b913;
                        }
                        .item-icon{
                            background: #262829;
                            color: #ffffff;
                            position: relative;
                            &::before, &::after{
                                content: '';
                                width: 120%;
                                height: 120%;
                                border-radius: 50%;
                                background: transparent;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            &::before{
                                border: 2px solid #262829;
                                opacity: 0.6;
                            }
                            &::after{
                                border: 1px solid #ffffff;
                                width: 160%;
                                height: 160%;
                                opacity: 0.4;
                            }
                        }
                        .item-status{
                            opacity: 1;
                        }
                    }
                    &.completed{
                        .item-icon{
                            background: #f0b913;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            i{
                                font-size: 18px;
                            }
                        }
                        .item-status{
                            opacity: 1;
                            .status-icon{
                                display: inline-block;
                            }
                        }
                        &::before{
                            background: #f0b913;
                        }
                    }
                    .item-title{
                        margin: 11px 0 1px;
                        color: #333537;
                        font-size: 12px;
                    }
                    .item-status{
                        color: #f0b913;
                        opacity: 0.75;
                        line-height: 16px;
                        font-size: 13px;
                        .status-icon{
                            display: none;
                        }
                    }
                    @media screen and (max-width: 1020px){
                        width: 15vw;
                        position: relative;
                        &::before{
                            width: 15vw;
                        }
                        .item-title,.item-status{
                            font-size: 14px;
                        }
                    }
                    
                }

                @media screen and (max-width: 768px){
                    flex-wrap: wrap;
                    margin-top: 15px;
                    .status-tree-item{
                        width: 100%;
                        position: relative;
                        display: flex;
                        gap: 20px;
                        height: 80px;
                        .item-icon{
                            top: 13px;
                        }
                        &::before{
                            width: 5px;
                            height: 100%;
                            left: 12.25px;
                            right: unset;
                            top: -15px;
                        }
                        &:last-child{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}


@media screen and (max-width: 575px) {
    .steps-status-section.form-steps .order-details .form-steps-status .status-tree .status-tree-item .item-icon {
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
        font-size: 11px;
    }
    .steps-status-section.form-steps .order-details .form-steps-status .status-tree .status-tree-item.active .item-icon::before {
        border: 1px solid #262829;
    }
    .steps-status-section.form-steps .order-details .form-steps-status .status-tree .status-tree-item.active .item-icon::after{
        width: 150%;
        height: 150%;
    }
    .steps-status-section.form-steps .order-details .form-steps-status .status-tree .status-tree-item::before {
        top: 13px;
        height: 3px;
    }
    .steps-status-section.form-steps .order-details .form-steps-status .status-tree .status-tree-item .item-title{
        font-size: 11px;
    }
    .steps-status-section.form-steps .order-details .form-steps-status .status-tree .status-tree-item {
        width: 72px;
    }
}