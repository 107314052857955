.profileImg {
    cursor: pointer;
    position: relative;
    &::before{
        content: "";
        background-color: rgba($color: #000000, $alpha: 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease;
        border-radius: 50%;
    }
    i {
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #fff;
    }

    &:hover {
        i {
            opacity: 1;
            visibility: visible;
        }
        &::before{
            opacity: 1;
            visibility: visible;
        }
    }
    input[type=file]{
        position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    cursor: pointer;
    }
}