.navWrap {
    .navLink {
        &:hover {
            color: #f0b913;
        }
        @media screen and (max-width: 575px) {
            padding: 0;
            padding-bottom: 10px;
        }
    }
}
.bgOverlay{
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 49;
    top: 0;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}
@media screen and (max-width: 991.99px) {
    .sideBar {
        position: fixed;
        z-index: 999;
        left: -250px;
        width: 250px;
        background-color: var(--secondaryClr);
        height: 100%;
        top: 0;
        bottom: 0;
        flex-direction: column;
        padding: 30px 15px;
        align-items: start;
        gap: 0px;
        transition: all 0.3s;

        &.active {
            left: 0;
        }
    }
}