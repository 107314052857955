.customTable {
    table {

        th {
            background-color: #262829;
            color: #fff;
        }

        tr {
            background-color: #f5f9fc;

            &:nth-of-type(even) {
                background-color: rgba(51, 53, 55, 0.5);
                color: #fff;
            }
        }

        th,
        td {
            padding: .5em 1em;
            text-align: center;
            font-size: 16px;
            @media screen and (max-width: 1370px) {
                font-size: 14px;
            }
            @media screen and (max-width: 991px) {
                font-size: 12px;
            }
        }
    }

}