.loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0,0,0,0.5);
    &.fixed{
        position: fixed;
    }
    img{
        width: 80px;
        height: auto;
    }
}