.ReceiptForm {
    input {
        width: calc(50% - 10px);
        @media screen and (max-width: 991.98px) {
            width: 100%;
        }
    }

    .formBtn {
        width: calc(50% - 10px);
        @media screen and (max-width: 575px) {
            width: 100%;
        }
    }

    .product-images {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .product-image {
            position: relative;
            display: inline-block;
            margin: 5;

            .remove-btn {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                background: #e61e1e;
                color: white;
                border: 0;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                padding: 0;
                font-size: 14px;
                line-height: 0;

                span {
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
}

table.customTable {
    th {
        padding: 15px 6px;
        background-color: var(--secondaryClr);
        color: #fff;
        @media screen and (max-width: 1199.98px) {
            width: auto !important;
        }
        @media screen and (max-width: 575px) {
            padding: 10px 24px;
        }
    }

    td {
        padding: 12px 5px;
        color: var(--secondaryClr);
        background-color: #fff;
    }

    tr {
        &:first-of-type {
            th {
                &:first-of-type {
                    border-top-left-radius: 8px;
                }

                &:last-of-type {
                    border-top-right-radius: 8px;
                }
            }
        }
        &:last-of-type {
            td {
                &:first-of-type {
                    border-bottom-left-radius: 8px;
                }

                &:last-of-type {
                    border-bottom-right-radius: 8px;
                }
            }
        }

        &:nth-of-type(even) {
            td {
                color: #fff;
        background-color: rgba($color: #333537, $alpha: 0.7);
            }
        }
    }
}