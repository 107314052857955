.orderFlow {
    position: relative;

    &:last-of-type {
        i {
            display: none;
        }
    }

    i {
        position: absolute;
        right: 0;
        display: block;
        transform: translate(50%, -50%);
top: 50%;
    }
}

@media screen and (max-width: 991px) {
    .orderFlow {
        &:nth-of-type(3) {
            i {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 575px) {
    .orderFlow {
        i {
            display: none;
        }
    }
}